@font-face {
    font-family: "DDIN";
    src: local("D-DIN"),
    url("../fonts/D-DIN.ttf") format("truetype");
}

@font-face {
    font-family: "DDINBold";
    src: local("D-DIN-Bold"),
    url("../fonts/D-DIN-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "DDINItalic";
    src: local("D-DIN-Italic"),
    url("../fonts/D-DIN-Italic.ttf") format("truetype");
}

::-webkit-scrollbar {
    height: 8px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: var(--background-color);
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: #A88A8A;
    border-radius: 20px;
}

* {
    box-sizing: border-box;
    font-family: 'D-DIN', sans-serif;
}

html {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}

body {
    -webkit-font-smoothing: antialiased;
    font-family: 'D-DIN', sans-serif;
}


a, a:visited, a:link, a:active {
    text-decoration: none;
    outline:0;
}

.fade {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.button-main-modal {
    color: var(--white-background-color);
    background: var(--Degrade-01, linear-gradient(90deg, var(--main-blue-light-color) 0%, var(--main-blue-color) 100%));
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    /* txt-menu */
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.button-secondary-modal {
    color: var(--white-background-color);
    background: var(--secondary-background-color, #7D98B3);
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    /* txt-menu */
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}