* {
    
    --background-color : #011222;
    --white-background-color : #FFF;
    
    --secondary-background-color : #7D98B3;
    --main-background-color : #E9F4FC;


    --main-blue-light-color : #2A7EC3;
    --main-blue-color : #1967B5;
    --main-blue-dark-color : #1C6BB8;

    --main-grey-color : #141F2A;

    --text-light-grey-color : #8B90A0;
    --text-blue-color : #031E37;
    --text-primary-color : #011222;
}

@property --_w {
    syntax: "<length>";
    inherits: true;
    initial-value: 100vw;
}

@property --_h {
    syntax: "<length>";
    inherits: true;
    initial-value: 100vh;
}

:root {
    --w: tan(atan2(var(--_w), 1px));
    --h: tan(atan2(var(--_h), 1px));
}