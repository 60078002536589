.actions-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    width: 100%;
}

.actions-bar-container.sticky {
    position: sticky;
    top : -30px;
    background-color: var(--main-background-color);
    z-index: 500;
    width: inherit;
}

.actions-bar-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 1;
}

.actions-bar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 1;
}

.actions-bar-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 1;
}