#main-menu {
    background: var(--background-color);
    min-height: 100%;
    padding-bottom: 120px;
    position: relative;
    user-select: none;
}

#menu-container {
    overflow: auto;
    overflow-x: hidden;
    -webkit-box-align:center;
    -webkit-box-pack:center;
    display:-webkit-box;
    padding-left: 15px;
}

.main-logo{
    width: 100px;
    margin: 80px 90px 60px 90px;
}

#logo-open {
    animation: logo-opening .5s normal forwards;
}
@keyframes logo-opening {
    0% {
        width: 50px;
        margin: 96px 0px;
    }
    100% {
        width: 100px;
        margin: 80px 90px 60px 90px;
    }
}

#logo-close {
    animation: logo-closing .5s normal forwards;
}
@keyframes logo-closing {
    0% {
        width: 100px;
        margin: 80px 90px 60px 90px;
    }
    100% {
        width: 50px;
        margin: 96px 0px;
    }
}

#RButtonNavBar {
    position: fixed;
    background-color: #393C45 !important;
    bottom: 50px;
    right: 50px;
    z-index: 5000;
    width: 100px;
    height: 100px;
}

.support-button, .support-button-close {
    position : absolute;
    bottom: 30px;
    border: 2px solid #58c9b7;
    background: #393C45 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px #00000029;
    border: 2px solid #58C9B7;
    border-radius: 5px;
    opacity: 1;
    color : #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.support-button {
    width: 240px;
    margin: 0px 20px;
    padding: 10px 30px;
    gap : 10px;
}

.support-button:hover {
    cursor: pointer;
}

.support-button span{
    font: normal normal 16px/18px 'DDIN';
}

.support-button-close {
    width: 40px;
    margin: 0px 5px;
}

.support-button-close span {
    visibility: hidden;
    width : 0px;
}

.version-site-web-container {
    position : absolute;
    bottom: 0px;
    width: 100%;
    font: normal normal 12px/14px 'DDIN';
    padding: 5px;
    color : #7F8079;
    display: flex;

    justify-content: center;
}

.retract-icon {
    color: #FFF;
    position: absolute;
    top: 20px;
    right: 12px;
}

.retract-icon:hover {
    cursor: pointer;
    color: #58C9B7;
}