.main-container {
    display: flex;
    flex-direction: row;
    color: #000;
}

.navbar-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    animation: navbar-opening .5s normal forwards;
}

@keyframes navbar-opening {
    0% {
        max-width: 50px;
    }
    100% {
        max-width: 280px;
    }
}

.navbar-container-closed {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    animation: navbar-closing .5s normal forwards;
}

@keyframes navbar-closing {
    0% {
        max-width: 280px;
    }
    100% {
        max-width: 50px;
    }
}

.global-view-container {
    padding: 0px 50px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* TODO A REVOIR POUR LA PARTIE MOBILE */
@media screen and (max-width: 768px) {
    .global-view-container {
        padding: 10px 20px;
    }
}