.user-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-right: 20px;
}

.user-profil-button:hover {
    cursor: pointer;
}

.user-profil-button {
    font: normal normal bold 14px/16px 'DDINBold';
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.user-profil-button .MuiAvatar-root {
    background-color: var(--background-color);
    font: normal normal bold 18px/20px 'DDINBold';
    width: 40px;
    height: 40px;
}

.user-actions-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.user-actions-buttons svg {
    height: 24px;
    width: 24px;
}