.login-container {
    width: 100%;
    height: 100vh;
    max-height: fit-content;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

/* PARTIE GAUCHE */

.login-form-container {
    flex-basis: 50%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color : var(--text-primary-color);
}

.login-main-logo {
    width: 220px;
    height: 170px;
    margin-top: 80px;
    margin-bottom: 80px;
}

.login-form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding : 0px 15%;
    gap: 40px;
}

.login-form-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding : 0px 10%;
    gap: 20px;
}

.login-form-inputs form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap : 20px;
}

.login-form-title {
    color: var(--Blanc-01, #E9F4FC);
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.login-form-label {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #8A8A8A;
}

.login-form-input{
    width: 100%;
    background: var(--main-grey-color) 0% 0% no-repeat padding-box;
    border: 0px;
    border-radius : 5px;
    padding: 25px;
    
    color: var(--secondary-background-color);
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login-form-error {
    width: 100%;
}

.login-button-connexion {
    width: 100%;
    height: 66px;
    border-radius: 5px;
    background: linear-gradient(90deg, var(--main-blue-light-color) 0%, var(--main-blue-color) 100%);

    color: var(--main-background-color);
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

/* PARTIE DROITE */

.login-background-container {
    background-image: url('asset/default/login-backView.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    position: relative;
    flex-basis: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-background-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--main-grey-color);
    opacity: 1;
    mix-blend-mode: color;
}

@media screen and (max-width: 768px) {
    .login-background-container {
        display: none;
        flex-basis: 0%;
    }

    .login-form-container {
        flex-basis: 100%;
    }
}