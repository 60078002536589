.RNavItemLink{
    width: 100%;
}

#RNavItem {
    position : relative;
    text-transform: none;
    padding : 10px;
    margin : 2px 0px;
    display: flex;
}

#RNavItem i {
    float: left;
    color : #8B90A0;
    margin-right: 14px;
    margin-left: min(40%, 50px);
}
#RNavItem p {
    color : #8B90A0;
    font: normal normal 1em 'DDIN';
    margin-top: 3px;
    min-width: 200px;
}

#RNavItem:hover {
    background-color: #393C45;
    cursor: pointer;
    text-decoration: none;
}

#RNavItem:hover p, #RNavItem:hover i {
    color : #FFF;
}

#RNavItem.active {
    background-color: #393C45;
}

#RNavItem.active p, #RNavItem.active i {
    color : #FFF;
}

#RNavItem.active:hover {
    cursor: default;
}